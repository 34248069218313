<template>
  <div>
    <div class="page-body">
      <title-bar title="用户维护">
        <el-button type="default" @click="$router.back()" icon="fa fa-angle-left" :loading="loading">
          返回
        </el-button>
      </title-bar>
      <el-form ref="editForm" :model="record" :rules="rules" label-width="120px" style="width: 600px;margin: auto">
        <el-form-item label="所属单位" prop="orgId">
          <org-select ref="orgSelect" v-model="record.orgId"></org-select>
        </el-form-item>
        <el-form-item label="姓名" prop="userName">
          <el-input type="text" v-model="record.userName" maxlength="50"></el-input>
        </el-form-item>
<!--        <el-form-item label="身份证号" prop="idCard">-->
<!--          <el-input type="text" v-model="record.idCard" maxlength="18"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="手机号" prop="mobile">
          <el-input type="text" v-model="record.mobile" minlength="11" maxlength="11"></el-input>
        </el-form-item>
        <el-form-item label="启用状态" prop="valid">
          <el-switch v-model="record.valid"></el-switch>
        </el-form-item>
      </el-form>
        <div class="button-container">
          <el-button type="primary" @click="save">
            <i class="fa fa-save"></i>
            保存
          </el-button>
          <el-button type="default" @click="$router.back()">
            <i class="fa fa-chevron-left"></i>
            取消
          </el-button>
        </div>
    </div>
  </div>
</template>

<script>
import BaseVue from "@/components/base/BaseVue";
import TitleBar from "@/components/base/TitleBar";
import TablePagination from "@/components/base/TablePagination";
import CodeSelect from "@/components/base/CodeSelect";
import Store from "@/utils/Store";
import OrgSelect from "@/components/base/OrgSelect";
import {validatorEnum} from "@/enums";

export default {
  components: {OrgSelect, CodeSelect, TablePagination, TitleBar},
  extends: BaseVue,
  data() {
    return {
      userId: "",
      rules: {
        orgId: [{required: true, message: "单位不能为空", trigger: "change"}],
        userName: [{required: true, message: "姓名不能为空", trigger: "blur"}],
        mobile: [{
          required: true, message: "手机号不能为空", trigger: "blur"
        },{
          validator: validatorEnum.mobile, trigger: "blur"
        }],
        sex: [{required: true, message: "性别不能为空", trigger: "change"}],
        idCard: [{
          required: true, message: "身份证号不能为空", trigger: "blur"
        },{
          validator: validatorEnum.idCard, trigger: "blur"
        }]
      },
      record: {
        valid: true
      }
    }
  },
  created() {
    this.userId = this.$route.query.userId || '';
    if(!this.userId) {
      return;
    }
    this.loading = true;
    this.postForm("/user/getById", {
      userId: this.userId
    }).then(dr => {
      this.record = dr.bean || {};
      this.$refs.orgSelect.init(this.record);
    }).catch( e => {
      this.$message.error(e.msg);
    }).finally(()=>{
      this.loading = false;
    })
  },
  methods: {
    save() {
      this.$refs["editForm"].validate(ok => {
        if(!ok) {
          return;
        }
        this.loading = true;
        this.postForm("/user/save",this.record, true).then(dr => {
          this.record = dr.bean;
          this.$router.back();
        }).finally(dr => {
          this.loading = false;
        });
      });
    }
  }
}
</script>

<style scoped lang="scss">
</style>
